.main-heading {
    color: #ffffff !important;
    text-align: center !important;
    padding-top: 5vh;
    font-weight: bold !important;
}

.secondary-heading {
    color: #ffffff !important;
    text-align: center !important;
    padding-top: 5vh;
    padding-bottom: 3vh;
    font-weight: bold !important;
}

.book-button {
    background-color: #ffffff15;
    color: #fff;
    border-radius: 40px;
    padding: 0.5rem 1.5rem;
    font-size: 26px;
    border: 3px solid #7a06ff35;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 5vh;
    text-decoration: none;
}

.book-button:hover {
    background-color: #ffffff35;
    transform: scale(1.05);
}

.accordionMui {
    border-radius: 10px !important;
    background-color: #ffffff15 !important;
    color: #fff !important;
    font-size: 16px;
    padding: 5px;
}

.card-main {
    border-radius: 50px !important;
    padding: 0.005rem;
    margin: 1.5rem;
}

.card-person {
    transform-style: preserve-3d;
    perspective: 1000px;
    transition: transform 0.3s ease-out;
    background-color: #ffffff05 !important;
    border-radius: 50px !important;
    padding: 2rem;
    margin: 1rem;
    border: none;
}

.card-person:hover {
    transform: translateY(-10px) rotateX(1deg);
 }

.card-person-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20vh;
    margin-bottom: 1rem;
    height: 20vh;
    width: 20vh;
    object-fit: cover !important;
}

.card-person-title {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    text-align: center;
}

.card-person-text {
    font-size: 18px;
    color: #fff;
    text-align: center;
}

.card {
    transform-style: preserve-3d;
    perspective: 1000px;
    transition: transform 0.3s ease-out;
    background-color: #ffffff15 !important;
    border-radius: 50px !important;
    padding: 2rem;
    margin: 1rem;
    border: none;
}

.card:hover {
   transform: translateY(-10px) rotateX(1deg);
}

.card-title {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
}

.card-text {
    font-size: 18px;
    color: #fff;
}

.card .btn {
    background-color: #E9EBEE;
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.card .btn:hover {
    background-color: #d1d3d6;
    color: #000;
    transition: background-color 0.3s ease, color 0.3s ease;
}


.card-img-top {
    border-radius: 15px;
    margin-bottom: 1rem;
    height: 20vh;
    width: 100%;
    object-fit: cover !important;
}

.card:nth-child(1) {
    /* background: linear-gradient(145deg, #6e3ec2 0%, #a044ff 100%); */
    color: #fff;
}

.card:nth-child(2) {
    background-color: #34C759;
    color: #fff;
}

.card:nth-child(3) {
    background-color: #FF9500;
    color: #fff;
}


@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
}